.about {
  padding-top: 2rem;
  margin: auto;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;

    .img-fluid {
      max-width: 100%;
    }
  }

  .about-heading-content {
    background-color: rgba(255, 255, 255, 0.85);
    max-width: 75%;
    margin: -5rem auto 2rem;
    position: relative;
    z-index: 1;
    border-radius: 0.25rem;

    .section-heading {
      text-transform: uppercase;

      .section-heading-upper {
        display: block;
        font-size: 1rem;
        font-weight: 800;
      }

      .section-heading-lower {
        display: block;
        font-size: 3rem;
        font-weight: 100;
      }
    }
  }
}
