body {
  background:  url("./assets/images/golf.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-family: 'Raleway';
}

.App {
  text-align: center;

  p {
    font-family: 'Lora';
  }
}
