.header {
  padding-top: 2rem;

  img {
    display: block;
    margin: auto;
    padding-bottom: 2rem;
  }

  .navbar-custom {
    background-color: rgba(0, 48, 11, 0.6);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .nav-item {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }


  button {
    background-color: transparent;
    font-family: 'Raleway';
    font-weight: 800;
    border-style: none;

    .link-active-orange {
      color: #e6a756;
    }
  }
}