.product {
  .card {
    width: 35rem;
  }

  img {
    max-width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
